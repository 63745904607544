import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {Button} from '@src/components/common/buttons/button';
import {Modal} from '@src/components/common/modal';
import {Typography} from '@src/components/common/typography';
import {useStore} from '@src/stores';

export const ReferralModal = observer(() => {
  const {user} = useStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {isOpen} = useTour();
  const navigate = useNavigate();

  const checkIsOpenModal = () => {
    if (isOpen) {
      setIsOpenModal(false);
    }

    if (user.isReferral && user.isNewUser && !isOpen && user._init) {
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkIsOpenModal();
    }, 3000);
  }, [user.isReferral, user.isNewUser, isOpen, user._init]);

  return (
    <Modal
      isCloseOnClickOverlay={false}
      title="Партнерская программа"
      opened={isOpenModal}
      onClose={() => setIsOpenModal(false)}
    >
      <div style={{maxWidth: 650, padding: 1}}>
        <Typography size={18} paragraph align="justify">
          Вы зарегистрировались по партнерской ссылке. В целях безопасности, мы не открываем доступ к вашим данным
          третьим лицам.
        </Typography>
        <br />
        <Typography size={18} paragraph align="justify">
          Можете выдать доступ на управление вашим рекламным кабинетом сами, если заключили договор с агентством, это
          легко.
        </Typography>
        <Button
          onClick={() => {
            navigate('/settings/partner');
            setIsOpenModal(false);
          }}
          style={{width: '100%', marginTop: 15}}
        >
          Выдать доступ
        </Button>
      </div>
    </Modal>
  );
});
