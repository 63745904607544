import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {yandexMetrika} from '@src/shared/utils/yandex-metrika';
import {useStore} from '@src/stores';

export const InitYandexMetrika = observer(() => {
  const {mpAccounts, user} = useStore();

  useEffect(() => {
    if (user.isNewUser) {
      yandexMetrika().targetFulfilled.newReg();
      mpAccounts.getMpAccountList().then(() => {
        if (mpAccounts.items.length > 0) {
          yandexMetrika().targetFulfilled.newCabinet();
        }
      });
    }
  }, [user.isNewUser]);
  return null;
});
