import {useContext} from 'react';

import {MediaQueryContext} from '@src/shared/context/media-query-context';

export const useMediaQuery = () => {
  const {isScreenNarrow} = useContext(MediaQueryContext);

  return {
    isScreenNarrow,
  };
};
