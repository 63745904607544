import {CURRENT_MP_ACCOUNT} from '../const/localStorage';

export function checkAndStoreQueryParams() {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.has(CURRENT_MP_ACCOUNT)) {
    const value = queryParams.get(CURRENT_MP_ACCOUNT);

    if (value !== null) {
      localStorage.setItem(CURRENT_MP_ACCOUNT, value);
    }
  }
}
