import {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import {Link, Modal, Typography} from '@components/index';
import LoadingButton from '@mui/lab/LoadingButton';
import {IconButton, TextField} from '@mui/material';
import {TelegramIcon, WhatsAppIcon} from '@src/components/common/icons';
import {COLORS} from '@src/shared/const/appPalette';
import {IS_SENDED_USER_CONTACTS} from '@src/shared/const/localStorage';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';

import {useStore} from '../../../../stores';

import {useEmotionWrapper} from './user-contacts.s';

export const UserContactsModal = observer(() => {
  const {classes} = useEmotionWrapper();
  const {utils, user, mpAccounts} = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [userContacts, setUserContacts] = useState({
    name: '',
    phone: '',
    telegramUsername: '',
  });

  const onCloseModal = () => {
    utils.closeOfferTrialModal();
  };

  const onSubmitUserContacts = () => {
    setIsLoading(true);
    user
      .addUserContacts(userContacts)
      .then(() => {
        toast.success('Спасибо! Свяжемся с вами в рабочее время');
        localStorage.setItem(IS_SENDED_USER_CONTACTS, 'true');
        utils.closeOfferTrialModal();
      })
      .catch(() => toast.error('Контакты не отправлены'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (
      mpAccounts.current?.hadPaidTariff === false &&
      user._init &&
      !window.location.pathname?.includes('/yandex-auth')
    ) {
      utils.openOfferTrialModal();
    }
  }, [user._init, mpAccounts.current?.tariff, mpAccounts.current?.hadPaidTariff]);

  return (
    <Modal opened={utils.isOpenOfferTrialModal} onClose={onCloseModal}>
      <div className={classes.root}>
        <Typography size={18} weight={600} align="center">
          Оставьте заявку и получите доступ к демо периоду
        </Typography>
        <div className={classes.formBlock}>
          <TextField
            required
            fullWidth
            type="text"
            size="small"
            placeholder="Ваше Имя"
            disabled={isLoading}
            value={userContacts.name}
            onChange={(e) => setUserContacts({...userContacts, name: e.target.value})}
          />
          <InputMask
            mask="+7 999 999 99 99"
            value={userContacts.phone}
            placeholder="Ваш телефон"
            onChange={(e) => setUserContacts({...userContacts, phone: e.target.value})}
          >
            {() => <TextField required fullWidth size="small" type="tel" placeholder="+7" />}
          </InputMask>
          <TextField
            size="small"
            fullWidth
            disabled={isLoading}
            value={userContacts.telegramUsername}
            onChange={(e) => setUserContacts({...userContacts, telegramUsername: e.target.value})}
            placeholder="Ник в telegram (по желанию)"
          />
          <LoadingButton
            fullWidth
            color="primary"
            variant="contained"
            loading={isLoading}
            onClick={onSubmitUserContacts}
            disabled={!userContacts.name || !userContacts.phone || isLoading}
          >
            Оставить заявку
          </LoadingButton>
          <Typography align="left" color={COLORS.BLACK_50} size={13}>
            Оставляя заявку, вы даете согласие на обработку{' '}
            <Link target="_blank" href={RESOURCE_URLS.PRAVO}>
              персональных данных
            </Link>
          </Typography>
        </div>
        <div className={classes.phoneContactContainer}>
          <Typography weight={600} size={16} align="left">
            Мы с вами быстро свяжемся в рабочее время, а на выходных чуть медленнее
          </Typography>
          <Link href="tel:+79677736350">+7 967 773 63 50</Link>
          <div className={classes.phoneContactTitle}>
            <IconButton onClick={() => window.open('https://wa.me/+79677736350', '_blank')} size="large">
              <WhatsAppIcon size="34" />
            </IconButton>
            <IconButton onClick={() => window.open(RESOURCE_URLS.SUPPORT_TELEGRAM, '_blank')} size="large">
              <TelegramIcon size="34" />
            </IconButton>
          </div>
        </div>
      </div>
    </Modal>
  );
});
