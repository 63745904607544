import {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import {MediaQueryContext} from '@src/shared/context/media-query-context';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './mobile-screen-placeholder.s';

interface IMobileScreenPlaceholder {
  children: JSX.Element;
}

export const MobileScreenPlaceholder: FC<IMobileScreenPlaceholder> = observer(({children}) => {
  const {classes} = useEmotionWrapper();
  const {user} = useStore();
  const [isMobile, setIsMobile] = useState(false);
  const [isScreenNarrow, setIsScreenNarrow] = useState(window.innerWidth < 900);

  const handleResize = useCallback(() => {
    setIsScreenNarrow(window.innerWidth < 900);
  }, []);

  const checkIfMobileDevice = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    const isMobileDevice = /android|iphone|ipod|ipad/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  useLayoutEffect(() => {
    checkIfMobileDevice();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, checkIfMobileDevice]);

  useEffect(() => {
    if (isMobile || isScreenNarrow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobile, isScreenNarrow]);

  return (
    <MediaQueryContext.Provider value={{isScreenNarrow}}>
      {children}
      {(isMobile || isScreenNarrow) && window.location.pathname !== '/login' && user.isAuth && user._init ? (
        <div className={classes.root}>
          <AspectRatioIcon sx={{fontSize: 42}} />
          {isMobile ? (
            <>
              SmartSeller удобнее использовать <br /> с компьютерной версии браузера
            </>
          ) : (
            'Пожалуйста, используйте браузер шириной не менее 900px'
          )}
        </div>
      ) : null}
    </MediaQueryContext.Provider>
  );
});
