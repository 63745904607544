import {emotionWrapper, keyframes} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

const animation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.9) ',
  },
  '50%': {
    opacity: 0.5,
    transform: 'scale(1.02) ',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1) ',
  },
});

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
    zIndex: 1,
    overflow: 'hidden',
    color: COLORS.WHITE,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    animation: `${animation} 0.4s ease-out`,
  },
}));
