import {emotionWrapper} from '@shared/lib';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    textAlign: 'justify',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    overflow: 'hidden',
    maxWidth: 350,
  },
  formBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  phoneContactContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    padding: 16,
    borderRadius: 12,
  },
  phoneContactTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  phoneContact: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      color: COLORS.PURPLE_100,
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
}));
